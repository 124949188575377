import React from "react";
// import { Link } from "gatsby"

import {
  Box,
  Badge,
  Center,
  Heading,
  Text,
  Stack,
  Container,
  Flex,
  SimpleGrid,
  Divider,
} from "@chakra-ui/react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Map from "../components/mapComponent";

import PictureBearbat from "../components/pictureBearbat";

function Facts({ title, desc, number = "0", ...rest }) {
  return (
    <Box
      bg="gray.100"
      p={5}
      shadow="md"
      borderWidth="1px"
      flex="1"
      borderRadius="xl"
      {...rest}
    >
      <Heading fontSize="xl">{title}</Heading>
      <Badge ml="2" colorScheme="cyan">
        {number}
      </Badge>
      <Text mt={4}>{desc}</Text>
    </Box>
  );
}

function StackOfFacts() {
  return (
    <Stack mx={["0rem", "4rem"]} direction={["column", "row"]} spacing="2rem">
      <Facts
        title="Bearbat sightings the last year"
        number="12"
        desc="We have many sightings. Verification is not always easy due to the often drunken state of the witnesses."
      />
      <Facts
        title="Bearbat clues the last year"
        number="0"
        desc="Not many clues yet."
      />
      <Facts
        title="Bearbat attacks the last year"
        number="1"
        desc="Last year, ISAF investigated 7 alleged interactions between human and BearBat, with an unusually low number of 0 unprovoked and 1 provoked bites in 2020."
      />
    </Stack>
  );
}

const IndexPage = () => (
  <Layout>
    <SEO title="Homepage about the majestic Bearbat beast" />
    <Container
      p="2rem"
      h="auto"
      bg="gray.100"
      borderRadius="xl"
      // centerContent
      maxW="3xl"
    >
      <Heading>The legend of the Bearbat!</Heading>
      <Text mt={4} fontSize="xl">
        A foul majestic beast of unlimited bowelshivering raw horror.
      </Text>
    </Container>

    <Stack
      py="2rem"
      mx="auto"
      direction={["column", "column", "row"]}
      spacing="2rem"
    >
      <Box
        p={2}
        w={["400px", "600px", "700px"]}
        h={["280px", "400px", "460px"]}
        // maxW="40%"
        // minW="30%"
        // h="400px"
        bg="gray.800"
        borderRadius="xl"
        // maxW="75%"
      >
        <PictureBearbat />
      </Box>
      <Box
        // d="table"
        p={2}
        w={["400px", "600px", "700px"]}
        h={["280px", "400px", "460px"]}
        // w="400px"
        // maxW="40%"
        // minW="30%"
        // h="400px"
        bg="gray.800"
        borderRadius="xl"
      >
        <Map scrolWheel={false} />
      </Box>
    </Stack>

    <Divider></Divider>

    <Center py="2rem">
      <Heading
        p={2}
        color="red.500"
        bg="gray.100"
        borderRadius="xl"
        centerContent
        maxW="3xl"
      >
        Stats about previous year
      </Heading>
    </Center>

    <StackOfFacts />

    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
);

export default IndexPage;
