import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import {
  Box,
  Flex,
  Square,
  Center,
  Text,
  Icon,
  Spacer,
  Heading,
  GridItem,
  Image,
  Stack,
} from "@chakra-ui/react";

const PictureBearbat = (props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Bearbat.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="Ferocious BearBat drawing"
    />
  );
};

export default PictureBearbat;
